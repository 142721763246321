<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Mensagens Proibidas</h1>
    <div class="grid grid-cols-2 md:grid-cols-6 gap-3">
      <button
        @click="$modal.show('novaMsgProibida')"
        type="button"
        class="mb-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
      >
        <span class="inline-block mr-2">{{ $t("txt-new") }}</span>
      </button>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Frase / Palavra
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ item.frase ? item.frase : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <button
                      @click="remove(item._id)"
                      type="button"
                      class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
                    >
                      Remover
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal name="novaMsgProibida" height="auto">
      <div class="px-3 py-4">
        <h2 class="text-lg mb-2 font-bold">Frase / Palavra Proibida</h2>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-12">
            <input
              v-model="form.frase"
              type="text"
              name="frase"
              id="frase"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="col-span-12">
            <button
              @click="novaMsgProibida"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [
        { url: "/operadores", value: "Operadores" },
        { url: "/operadores/msgproibida", value: "Mensagens Proibidas" },
      ],
      route: "operadores",
      list: [],
      total: 0,
      form: {
        frase: "",
      },
    };
  },
  methods: {
    async start() {
      const operadoresReq = await this.$http.post("/v1/operador/msgproibida/list");
      this.list = operadoresReq.data.data;
      this.total = operadoresReq.data.total;
    },

    async novaMsgProibida() {
      if (!this.form.frase) {
        return this.$vToastify.error("Digite a frase / palavra");
      }

      const req = await this.$http.post(`/v1/operador/msgproibida`, this.form);

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");
        this.form.frase = "";
        this.$modal.hide("novaMsgProibida");
        this.start();
      } else {
        this.$vToastify.error(req.data.err);
      }
    },

    async remove(msg) {
      if (confirm("Tem certeza que deseja excluir esse operador?")) {
        const resp = await this.$http.delete(`/v1/operador/msgproibida/${msg}`);
        if (!resp.data.success) {
          this.$vToastify.error(resp.data.err);
          return;
        }
        this.$vToastify.success("Operador removido");
        this.start();
      }
    },
  },
  async beforeMount() {
    this.start();
  },
};
</script>
